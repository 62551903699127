import { graphql, Link } from "gatsby"
import Img from "gatsby-image"
import React from "react"
import Layout from "../../layouts/Layout"
import SiteMetadata from "../../components/SiteMetadata"

const SupportPage = ({ data }) => (
  <Layout>
    <SiteMetadata title="Support" description="Sample description" />

    <div className="bg-gray-100">
      <div className="container py-12 lg:pb-16">
        <div className="flex flex-wrap">
          <div className="w-full md:w-1/2 xl:w-3/5 pb-8 md:pb-0">
            <h1 className="text-3xl leading-tight font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              Support
            </h1>

            <h2 className="text-xl leading-tight font-semibold tracking-tight text-blue-600 sm:text-2xl">
              How can we help you?
            </h2>
            <br />
            <div className="mt-4 leading-loose">
              <ul>
                <li>
                • <a
                    href="mailto:support@blueboatgame.com"
                    className="text-blue-600 hover:text-blue-800"
                  >
                    Submit a Ticket
                  </a>
                </li>
                <li>
                • <Link className="text-blue-600 hover:text-blue-800" to={`/support/privacy-policy`}>
                    Privacy Policy
                  </Link>
                </li>
                <li>
                • <Link className="text-blue-600 hover:text-blue-800" to={`/support/terms-of-service`}>
                    Terms of Service
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="w-full md:w-1/2 xl:w-2/5 md:pl-12">
            <Img
              fluid={data.connect.childImageSharp.fluid}
              alt="Support"
              className="rounded-md shadow-md"
            />
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default SupportPage

export const query = graphql`
  query {
    connect: file(relativePath: { eq: "connect.png" }) {
      childImageSharp {
        fluid(maxWidth: 600, maxHeight: 480, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
